<template>
  <div class="evo-payment-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMWalletInteroperabilityServiceSectionOne />
      <EvoMWalletInteroperabilityServiceSectionTwo />
      <EvoMWalletInteroperabilityServiceSectionThree />
      <EvoMPartnersSectionTwo />
      <EvoMWalletInteroperabilityServiceSectionFive />
    </template>
    <template v-else>
      <EvoPaymentSectionOne />
      <EvoPaymentSectionTwo />
      <EvoPaymentSectionThree />
      <EvoPartnersSectionTwo />
      <EvoPaymentSectionFive />
    </template>
  </div>
</template>

<script>
import EvoPaymentSectionOne from '@/components/payment_sections/Section_1.vue';
import EvoPaymentSectionTwo from '@/components/payment_sections/Section_2.vue';
import EvoPaymentSectionThree from '@/components/payment_sections/Section_3.vue';
import EvoPartnersSectionTwo from '@/components/partners_sections/Section_2.vue';
import EvoPaymentSectionFive from '@/components/payment_sections/Section_5.vue';

// mobile imports
import EvoMWalletInteroperabilityServiceSectionOne from '@/mobile/components/payment_sections/MSection_1.vue';
import EvoMWalletInteroperabilityServiceSectionTwo from '@/mobile/components/payment_sections/MSection_2.vue';
import EvoMWalletInteroperabilityServiceSectionThree from '@/mobile/components/payment_sections/MSection_3.vue';
import EvoMPartnersSectionTwo from '@/mobile/components/partners_sections/MSection_2.vue';
import EvoMWalletInteroperabilityServiceSectionFive from '@/mobile/components/payment_sections/MSection_5.vue';
export default {
  name: 'EvoPayment',
  components: {
    EvoPaymentSectionOne,
    EvoPaymentSectionTwo,
    EvoPaymentSectionThree,
    EvoPartnersSectionTwo,
    EvoPaymentSectionFive,

    EvoMWalletInteroperabilityServiceSectionOne,
    EvoMWalletInteroperabilityServiceSectionTwo,
    EvoMWalletInteroperabilityServiceSectionThree,
    EvoMPartnersSectionTwo,
    EvoMWalletInteroperabilityServiceSectionFive
  }
};
</script>

<style lang="scss" scoped>
.evo-payment-wrapper {
  background: $header_bg_color;
}
</style>
