<template>
  <div
    class="section-3 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row class="px-0">
      <v-col cols="12" sm="12" md="5" align-self="center">
        <div
          class="evo-section3-title evo-text-23"
          :class="{ 'evo-px-text-23': $vuetify.breakpoint.xsOnly }"
          data-aos="fade-up"
        >
          {{ $t("payment.save_your_time_on_connecting_to_overseas_wallets") }}
        </div>
        <img loading="lazy" 
          class="evo-wallet-acceptance-img"
          :class="{ 'evo-img-auto': $vuetify.breakpoint.xsOnly }"
          src="@/assets/payment/wallet-acceptance.png"
          data-aos="fade-up"
          data-aos-delay="100"
        />
      </v-col>
      <v-col cols="12" md="1" v-if="!$vuetify.breakpoint.xsOnly"></v-col>
      <v-col cols="12" sm="12" md="6">
        <div
          class="d-flex flex-column justify-space-between"
          style="height: 100%"
        >
          <div
            v-for="item in items"
            :key="item.key"
            class="d-flex evo-item-wrapper"
            :style="{
              'margin-bottom': $vuetify.breakpoint.xsOnly ? '20px' : '',
            }"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img loading="lazy" 
              :src="item.src"
              class="evo-item-img"
              :class="{ 'evo-px-item-img': $vuetify.breakpoint.xsOnly }"
            />
            <div class="d-flex flex-column justify-space-between">
              <span
                class="evo-item-title evo-mb-6 evo-text-16"
                :class="{ 'evo-px-text-14': $vuetify.breakpoint.xsOnly }"
              >
                {{ $t(item.title) }}
              </span>
              <span
                class="evo-item-desc evo-text-11 evo-opensans-regular"
                :class="{ 'evo-px-text-8': $vuetify.breakpoint.xsOnly }"
                >{{ $t(item.desc) }}</span
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="evo-bg-dot-rect"></div>
    <!-- <div class="evo-bg-light-blue-rect"></div> -->
  </div>
</template>

<script>
import { walletInteroperabilityServiceSection3Mixin } from '@/mixins/payment.js';
export default {
  name: "EvoPaymentSectionThree",
  mixins: [walletInteroperabilityServiceSection3Mixin]
};
</script>

<style lang="scss" scoped>
.section-3 {
  position: relative;
  z-index: 1;

  .evo-section3-title {
    font-weight: bold;
    color: $title_color;
    margin-bottom: 39px;
    position: relative;
    z-index: 1;
  }

  .evo-wallet-acceptance-img {
    position: relative;
    z-index: 1;
    width: 204px;
    height: auto;
  }

  .evo-item-wrapper {
    position: relative;
    z-index: 1;
    .evo-item-img {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .evo-px-item-img {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .evo-item-title {
      font-weight: bold;
      color: $title_color;
    }

    .evo-item-desc {
      color: #a0a3a8;
    }
  }

  .evo-bg-dot-rect {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 300px;
    width: 200px;
    height: 176px;
    background-image: url("~@/assets/payment/dot-rect.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .evo-bg-light-blue-rect {
    position: absolute;
    background-color: #f4f6f9;
    z-index: 0;
    left: 0px;
    bottom: 0px;
    width: 70%;
    height: 90%;
  }
}
</style>
