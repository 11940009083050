<template>
  <div
    class="section-2 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row class="px-0" align="center">
      <v-col cols="12" sm="12" md="6">
        <div
          class="evo-section2-title evo-text-23"
          :class="{ 'evo-px-text-23': $vuetify.breakpoint.xsOnly }"
          data-aos="fade-up"
        >
          {{ $t("partner.for_partners_own_users") }}
        </div>
        <div
          class="evo-left-slide-item"
          :class="{ 'active-slide': item.active }"
          v-for="item in items"
          :key="item.key"
        >
          <div
            class="evo-item-title evo-text-16"
            :class="{ 'evo-px-text-14': $vuetify.breakpoint.xsOnly }"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {{ $t(item.title) }}
          </div>
          <div
            class="evo-item-subtitle evo-text-11 evo-opensans-regular"
            :class="{ 'evo-px-text-9': $vuetify.breakpoint.xsOnly }"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {{ $t(item.subtitle) }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-carousel
          cycle
          hide-delimiter-background
          :show-arrows="false"
          @change="onSlideChange"
          :interval="3000"
          :height="dynamicHeight"
        >
          <v-carousel-item v-for="item in slides" :key="item.key">
            <img loading="lazy" 
              class="evo-slide-img"
              :src="item.src"
              data-aos="fade-up"
              data-aos-delay="150"
            />
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <EvoRect class="evo-section2-rect1 mb-2" />
    <EvoRect color="skyBlue" class="evo-section2-rect2" />
    <EvoEllipse
      class="evo-section2-ellipse"
      transform="rotate(0deg)"
      width="96px"
      height="124px"
    />
  </div>
</template>

<script>
import EvoRect from "@/components/Rect.vue";
import EvoEllipse from "@/components/Ellipse.vue";
export default {
  name: "EvoPartnersSectionTwo",
  components: {
    EvoRect,
    EvoEllipse,
  },
  data: () => ({
    items: [
      {
        key: 1,
        active: true,
        title: "partner.title_section_2_1",
        subtitle: "partner.subtitle_section_2_1",
      },
      {
        key: 2,
        active: false,
        title: "partner.title_section_2_2",
        subtitle: "partner.subtitle_section_2_2",
      },
      {
        key: 3,
        active: false,
        title: "partner.title_section_2_3",
        subtitle: "partner.subtitle_section_2_3",
      },
    ],
    slides: [
      {
        key: 1,
        src: require("@/assets/partners/section2-slide3.png"),
      },
      {
        key: 2,
        src: require("@/assets/partners/section2-slide1.png"),
      },
      {
        key: 3,
        src: require("@/assets/partners/section2-slide2.png"),
      },
    ],
  }),
  computed: {
    dynamicHeight() {
      const winWidth = this.$vuetify.breakpoint.width;
      const newWidth = parseInt((310 / 1080) * winWidth);
      if (winWidth < 960) {
        return parseInt((600 / 960) * winWidth);
      }
      return Math.min(newWidth, 500);
    },
  },
  methods: {
    onSlideChange(index) {
      this.items.forEach((item, i) => {
        item.active = i === index;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-2 {
  position: relative;
  overflow: hidden;
  background: #f4f6f9;

  .evo-section2-title {
    font-weight: bold;
    color: #090a0e;
    margin-bottom: 26px;
  }

  .evo-left-slide-item {
    .evo-item-title {
      font-weight: bold;
      color: #8f9093;
      margin-bottom: 5px;
    }

    .evo-item-subtitle {
      color: #a2a3a7;
      margin-bottom: 18px;
    }
  }

  .active-slide {
    .evo-item-title {
      color: #2161ff;
      transition: color 1s;
    }

    .evo-item-subtitle {
      color: #090a0e;
      transition: color 1s;
    }
  }

  .evo-slide-img {
    width: 100%;
    height: auto;
  }

  ::v-deep(.v-item-group) {
    .v-btn {
      color: #767a80;
    }

    .v-item--active {
      color: #589aff;
    }
  }

  .evo-section2-rect1 {
    position: absolute;
    width: 63px;
    height: 47px;
    right: 0;
    bottom: 90px;
  }

  .evo-section2-rect2 {
    position: absolute;
    width: 169px;
    height: 29px;
    right: 0;
    bottom: 60px;
  }

  .evo-section2-ellipse {
    position: absolute;
    right: -10px;
    bottom: 220px;
  }
}
</style>
