<template>
  <div class="section-3 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <v-row>
        <v-col cols="12" align-self="center">
          <div class="evo-section3-title text-center evo-text-58">
            {{ $t('payment.save_your_time_on_connecting_to_overseas_wallets') }}
          </div>
        </v-col>
        <v-col cols="12" class="evo-img-wrapper">
          <img loading="lazy" class="evo-wallet-acceptance-img" src="@/assets/payment/wallet-acceptance.png" />
        </v-col>
        <v-col cols="12">
          <div class="d-flex flex-column justify-space-between" style="height: 100%">
            <div v-for="item in items" :key="item.key" class="d-flex evo-item-wrapper">
              <img loading="lazy" :src="item.src" class="evo-item-img" />
              <div class="d-flex flex-column justify-space-between">
                <span class="evo-item-title evo-mb-6 evo-text-42">
                  {{ $t(item.title) }}
                </span>
                <span class="evo-item-desc evo-text-24 evo-opensans-regular">
                  {{ $t(item.desc) }}
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="evo-bg-dot-rect"></div>
    </div>
  </div>
</template>

<script>
import { walletInteroperabilityServiceSection3Mixin } from '@/mixins/payment.js';
export default {
  name: 'EvoMWalletInteroperabilityServiceSectionThree',
  mixins: [walletInteroperabilityServiceSection3Mixin]
};
</script>

<style lang="scss" scoped>
.section-3 {
  position: relative;
  z-index: 1;

  .evo-section3-title {
    font-weight: bold;
    color: $title_color;
    margin-bottom: 8px;
    position: relative;
    z-index: 1;
  }

  .evo-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    .evo-wallet-acceptance-img {
      width: 640px;
      height: auto;
    }
  }

  .evo-item-wrapper {
    position: relative;
    z-index: 1;
    margin-bottom: 48px;
    .evo-item-img {
      width: 54px;
      height: 54px;
      margin-right: 24px;
    }

    .evo-item-title {
      color: $title_color;
      margin-bottom: 8px;
    }

    .evo-item-desc {
      color: #a0a3a8;
    }
  }

  .evo-bg-dot-rect {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 300px;
    width: 200px;
    height: 176px;
    background-image: url('~@/assets/payment/dot-rect.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
}
</style>
