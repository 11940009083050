import EvoRect from "@/components/Rect.vue";
import EvoEllipse from "@/components/Ellipse.vue";

export const partnersSection2Mixin = {
    components: {
        EvoRect,
        EvoEllipse,
    },
    data: () => ({
        items: [
            {
                key: 1,
                active: true,
                title: "partner.title_section_2_1",
                subtitle: "partner.subtitle_section_2_1",
            },
            {
                key: 2,
                active: false,
                title: "partner.title_section_2_2",
                subtitle: "partner.subtitle_section_2_2",
            },
            {
                key: 3,
                active: false,
                title: "partner.title_section_2_3",
                subtitle: "partner.subtitle_section_2_3",
            },
        ],
        slides: [
            {
                key: 1,
                src: require("@/assets/partners/section2-slide3.png"),
            },
            {
                key: 2,
                src: require("@/assets/partners/section2-slide1.png"),
            },
            {
                key: 3,
                src: require("@/assets/partners/section2-slide2.png"),
            },
        ],
    }),
    computed: {
        dynamicHeight() {
            const winWidth = this.$vuetify.breakpoint.width;
            const newWidth = parseInt((310 / 1080) * winWidth);

            // phone
            if (winWidth < 600) {
                return 320;
            }

            // pad
            if (winWidth < 960) {
                return parseInt((860 / 960) * winWidth);
            }

            return Math.min(newWidth, 500);
        },
    },
    methods: {
        onSlideChange(index) {
            this.items.forEach((item, i) => {
                item.active = i === index;
            });
        },
    },
}