<template>
  <div class="section-1 section-1-bg evo-ph-206 evo-pv-60">
    <v-row class="evo-wrapper" no-gutters>
      <v-col cols="12" sm="12" md="5" class="px-0">
        <img
          loading="lazy"
          src="@/assets/payment/phone-payment.png"
          class="evo-img-auto"
          data-aos="fade-up"
          data-aos-delay="100"
        />
      </v-col>
      <v-col cols="12" sm="12" md="7" class="evo-content">
        <div
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          class="evo-section1-title evo-text-34"
        >
          {{ $t("payment.section_1_title_1") }}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          class="evo-section1-subtitle evo-text-15 evo-opensans-regular"
        >
          {{ $t("payment.desc_section_1_1") }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "EvoPaymentSectionOne",
};
</script>

<style lang="scss" scoped>
.section-1 {
  color: #ffffff;
  position: relative;
  background-color: $nav_color;

  .evo-wrapper {
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 12px;
  }

  .evo-content {
    padding-top: 36px;
    padding-left: 22px;
  }

  .evo-section1-title {
    white-space: pre-line;
    line-height: 44px;
    margin-bottom: 24px;
    margin-right: -20%;
  }

  .evo-section1-subtitle {
    line-height: 23px;
    margin-right: -10%;
  }
}
</style>
