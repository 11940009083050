<template>
  <div class="section-5 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <v-row align="center">
        <v-col cols="12">
          <div class="evo-section5-title text-center evo-text-58" v-html="$t('payment.boost_the_business_and_target_your_customers')"></div>
          <div class="evo-section5-subtitle text-center evo-text-30 evo-opensans-regular">
            {{ $t('payment.desc_section_5_1') }}
          </div>
        </v-col>
        <v-col cols="12" class="evo-img-wrapper">
          <img loading="lazy" class="evo-section5-img" src="@/assets/payment/dashboard.png" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvoMWalletInteroperabilityServiceSectionFive'
};
</script>

<style lang="scss" scoped>
.section-5 {
  position: relative;

  .evo-section5-title {
    color: $title_color;
    margin-bottom: 32px;
  }

  .evo-section5-subtitle {
    color: #a0a3a8;
  }

  .evo-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .evo-section5-img {
      width: 640px;
      height: auto;
    }
  }
}
</style>
