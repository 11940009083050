<template>
  <div class="section-1 section-1-bg evo-f-center evo-pv-100">
    <div class="evo-mobile">
      <v-row>
        <v-col cols="12">
          <div class="evo-section1-title text-center evo-text-66">
            {{ $t('payment.section_1_title_1') }}
          </div>
          <div class="evo-section1-subtitle text-center evo-text-33 evo-opensans-regular">
            {{ $t('payment.desc_section_1_1') }}
          </div>
        </v-col>
        <v-col cols="12" class="evo-img-wrapper">
          <img loading="lazy" src="@/assets/payment/phone-payment-mobile.png" class="evo-img" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvoMWalletInteroperabilityServiceSectionOne'
};
</script>

<style lang="scss" scoped>
.section-1 {
  color: #ffffff;
  position: relative;
  background-color: $nav_color;

  &.section-1-bg {
    width: 100% !important;
    height: 100% !important;
  }

  .evo-img-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .evo-img {
      width: 640px;
    }
  }

  .evo-section1-title {
    color: white;
    margin-bottom: 24px;
  }

  .evo-section1-subtitle {
    color: white;
    margin-bottom: 48px;
  }
}
</style>
