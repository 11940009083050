<template>
  <div
    class="section-5 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row class="px-0" align="center">
      <v-col cols="12" sm="12" md="6">
        <div
          class="evo-section5-title evo-text-23 evo-mb-23"
          :class="{ 'evo-px-text-23': $vuetify.breakpoint.xsOnly }"
          v-html="$t('payment.boost_the_business_and_target_your_customers')"
          data-aos="fade-up"
        ></div>
        <div
          class="evo-section5-subtitle evo-text-12 evo-opensans-regular"
          :class="{ 'evo-px-text-8': $vuetify.breakpoint.xsOnly }"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {{ $t("payment.desc_section_5_1") }}
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <img loading="lazy" 
          class="evo-section5-img"
          src="@/assets/payment/dashboard.png"
          :class="{ 'evo-img-auto': $vuetify.breakpoint.xsOnly }"
          data-aos="fade-up"
          data-aos-delay="150"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "EvoPaymentSectionFive",
};
</script>

<style lang="scss" scoped>
.section-5 {
  position: relative;

  .evo-section5-title {
    font-weight: bold;
    color: $title_color;
  }

  .evo-section5-subtitle {
    color: #a0a3a8;
  }

  .evo-section5-img {
    width: 395px;
    height: auto;
  }
}
</style>
