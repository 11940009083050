import EvoEllipse from '@/components/Ellipse.vue';
export const walletInteroperabilityServiceSection2Mixin = {
    data: () => ({
        items: [
            {
                key: 1,
                src: require('@/assets/payment/contract-relationship.png'),
                title: 'payment.title_section_2_1',
                desc: 'payment.desc_section_2_1'
            },
            {
                key: 2,
                src: require('@/assets/payment/compliace-evonet.png'),
                title: 'payment.title_section_2_2',
                desc: 'payment.desc_section_2_2'
            },
            {
                key: 3,
                src: require('@/assets/payment/settlement-arrangement.png'),
                title: 'payment.title_section_2_3',
                desc: 'payment.desc_section_2_3'
            }
        ]
    }),
    components: {
        EvoEllipse
    }
}

export const walletInteroperabilityServiceSection3Mixin = {
    data: () => ({
        items: [
            {
                key: 1,
                src: require("@/assets/payment/ex-network.png"),
                title: "payment.title_section_3_1",
                desc: "payment.desc_section_3_1",
            },
            {
                key: 2,
                src: require("@/assets/payment/sys-connection.png"),
                title: "payment.title_section_3_2",
                desc: "payment.desc_section_3_2",
            },
            {
                key: 3,
                src: require("@/assets/payment/ma-platform.png"),
                title: "payment.title_section_3_3",
                desc: "payment.desc_section_3_3",
            },
        ],
    }),
}