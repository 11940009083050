<template>
  <div class="section-2 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <v-row align="center">
        <v-col cols="12">
          <div class="evo-section2-title evo-text-58">
            {{ $t('partner.for_partners_own_users') }}
          </div>
          <div class="evo-left-slide-item" :class="{ 'active-slide': item.active }" v-for="item in items" :key="item.key">
            <div class="evo-item-title evo-text-42">
              {{ $t(item.title) }}
            </div>
            <div class="evo-item-subtitle evo-text-24 evo-opensans-regular">
              {{ $t(item.subtitle) }}
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-carousel cycle hide-delimiter-background :show-arrows="false" @change="onSlideChange" :interval="3000" :height="dynamicHeight">
            <v-carousel-item v-for="item in slides" :key="item.key">
              <img loading="lazy" class="evo-slide-img" :src="item.src" />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { partnersSection2Mixin } from '@/mixins/partners.js';
export default {
  name: 'EvoMPartnersSectionTwo',
  mixins: [partnersSection2Mixin]
};
</script>

<style lang="scss" scoped>
.section-2 {
  position: relative;
  overflow: hidden;
  background: #f4f6f9;

  .evo-section2-title {
    font-weight: bold;
    color: #090a0e;
    margin-bottom: 48px;
  }

  .evo-left-slide-item {
    margin-bottom: 48px;
    .evo-item-title {
      font-weight: bold;
      color: #8f9093;
      margin-bottom: 8px;
    }

    .evo-item-subtitle {
      color: #a2a3a7;
      margin-bottom: 18px;
    }
  }

  .active-slide {
    .evo-item-title {
      color: #2161ff;
      transition: color 1s;
    }

    .evo-item-subtitle {
      color: #090a0e;
      transition: color 1s;
    }
  }

  .evo-slide-img {
    width: 100%;
    height: auto;
  }

  ::v-deep(.v-item-group) {
    .v-btn {
      color: #767a80;
    }

    .v-item--active {
      color: #589aff;
    }
  }
}
</style>
